import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ChannelSelectorModalComponent } from './channel-selector-modal/channel-selector-modal.component';
import { ChannelSelectorComponent } from './channel-selector.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [ChannelSelectorComponent, ChannelSelectorModalComponent],
  exports: [ChannelSelectorComponent],
})
export class ChannelSelectorModule {}
